import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d83788c4 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _7a42b4c8 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _96545672 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _c501e25c = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _37d27623 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _6fa240bb = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _f0f52e30 = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _7a2ddfcd = () => interopDefault(import('../pages/Installment/index.vue' /* webpackChunkName: "pages/Installment/index" */))
const _f526a84e = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _71ad2515 = () => interopDefault(import('../pages/Reviews1111111.vue' /* webpackChunkName: "pages/Reviews1111111" */))
const _89c285f0 = () => interopDefault(import('../pages/selector-car/index.vue' /* webpackChunkName: "pages/selector-car/index" */))
const _5cfe6d3d = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _6fbf4f31 = () => interopDefault(import('../pages/test-drive/index.vue' /* webpackChunkName: "pages/test-drive/index" */))
const _57652808 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _d24039a6 = () => interopDefault(import('../pages/Trade-in.vue' /* webpackChunkName: "pages/Trade-in" */))
const _73e5c3f2 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _3c29f683 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2c24e3de = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _16de5ba4 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _d69e36fe = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _1863178e = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _d83788c4,
    name: "About"
  }, {
    path: "/Banks",
    component: _7a42b4c8,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _96545672,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _c501e25c,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _37d27623,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _6fa240bb,
    name: "Credit"
  }, {
    path: "/Favorites",
    component: _f0f52e30,
    name: "Favorites"
  }, {
    path: "/Installment",
    component: _7a2ddfcd,
    name: "Installment"
  }, {
    path: "/Privacy",
    component: _f526a84e,
    name: "Privacy"
  }, {
    path: "/Reviews1111111",
    component: _71ad2515,
    name: "Reviews1111111"
  }, {
    path: "/selector-car",
    component: _89c285f0,
    name: "selector-car"
  }, {
    path: "/services",
    component: _5cfe6d3d,
    name: "services"
  }, {
    path: "/test-drive",
    component: _6fbf4f31,
    name: "test-drive"
  }, {
    path: "/Thanks",
    component: _57652808,
    name: "Thanks"
  }, {
    path: "/Trade-in",
    component: _d24039a6,
    name: "Trade-in"
  }, {
    path: "/Credit/:bank",
    component: _73e5c3f2,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _3c29f683,
    name: "index"
  }, {
    path: "/:category",
    component: _2c24e3de,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _16de5ba4,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _d69e36fe,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _1863178e,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
